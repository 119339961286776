import Experience from '../Experience.js'
import * as THREE from 'three';

export default class Island
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        this.resources = this.experience.resources
        this.resource = this.resources.items.island

        this.normals = false;
        this.normalMaterial = new THREE.MeshNormalMaterial();

        if (this.debug) {
            this.debugFolder = this.debug.addFolder('island')
        }

        this.setModel();
        this.setDebug();
    }

    setModel() {
        this.model = this.resource.scene
        this.model.scale.set(0.001, 0.001, 0.001)
        this.scene.add(this.model)
        this.material = this.model.children[0].material;

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh) {
                child.castShadow = true
            }
        })
    }

    setDebug() {
        if (!this.debug) return;
        this.debugFolder.add(this.material, 'wireframe');
        this.debugFolder
            .add(this, 'normals')
            .onChange((enabled) => {
               this.updateMaterial(enabled);
            });
    }

    updateMaterial(normal) {
        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh) {
                child.material = normal ? this.normalMaterial : this.material;
                child.material.needsUpdate = true;
                child.castShadow = true
            }
        })
    }

    update() {

    }
}