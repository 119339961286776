export default [
    {
        name: 'base',
        data: {},
        items:
        [
            { name: 'lennaTexture', path: '/assets/lenna.png', type: 'texture' },
            { name: 'island', path: '/assets/island/rapid.gltf', type: 'gltfModel' },
            {
                name: 'environmentMapTexture',
                type: 'cubeTexture',
                path:
                [
                    '/assets/environmentMap/px.jpg',
                    '/assets/environmentMap/nx.jpg',
                    '/assets/environmentMap/py.jpg',
                    '/assets/environmentMap/ny.jpg',
                    '/assets/environmentMap/pz.jpg',
                    '/assets/environmentMap/nz.jpg'
                ]
            }
        ]
    }
]