import EventEmitter from './EventEmitter.js'
import Experience from '../Experience.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as THREE from 'three'

export default class Resources extends EventEmitter
{
    /**
     * Constructor
     */
    constructor()
    {
        super()

        this.experience = new Experience()
        this.renderer = this.experience.renderer.instance

        this.setLoaders()

        this.toLoad = 0
        this.loaded = 0
        this.items = {}
    }

    /**
     * Set loaders
     */
    setLoaders()
    {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader();
        this.loaders.textureLoader = new THREE.TextureLoader();
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
    }

    /**
     * Load
     */
    load(_resources = [])
    {
        // Load each source
        for(const _resource of _resources)
        {
            this.toLoad++
            if(_resource.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    _resource.path,
                    (file) =>
                    {
                        this.fileLoadEnd(_resource, file)
                    }
                )
            }
            else if(_resource.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    _resource.path,
                    (file) =>
                    {
                        this.fileLoadEnd(_resource, file)
                    }
                )
            }
            else if(_resource.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    _resource.path,
                    (file) =>
                    {
                        this.fileLoadEnd(_resource, file)
                    }
                )
            }
        }
    }

    /**
     * File load end
     */
    fileLoadEnd(_resource, _data)
    {
        this.loaded++
        this.items[_resource.name] = _data

        this.trigger('fileEnd', [_resource, _data])

        if(this.loaded === this.toLoad)
        {
            this.trigger('end')
        }
    }
}
